import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/sass/style.scss'
import jQuery from 'jquery'
import axios from 'axios'
import 'bootstrap/dist/js/bootstrap'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

Vue.config.productionTip = false

// Global variables
Vue.prototype.$http = axios
window.$ = jQuery
Vue.prototype.$eventBus = new Vue()

// Font Awesome
library.add(faChevronLeft)
Vue.component('font-awesome-icon', FontAwesomeIcon)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

<template>
  <div id="sidebar">
    <div id="sidebar-top">
      <div id="logo">
        <img :src="require('@/assets/img/mnnu_logo_lettermark.svg')" alt="MNNU">
      </div>
    </div>
    <nav id="sidebar-nav">
      <ul class="nav">
        <li class="nav-item">
          <router-link :to="{name: 'contacts'}" class="nav-link">
            <div class="nav-icon btn btn-mnnu-menu">
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-house" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"/>
                <path fill-rule="evenodd" d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"/>
              </svg>
            </div>
            <span>Behandelaar</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{name: 'user'}" class="nav-link">
            <div class="nav-icon btn btn-mnnu-menu">
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-person-badge" viewBox="0 0 16 16">
                <path d="M6.5 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zM11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                <path d="M4.5 0A2.5 2.5 0 0 0 2 2.5V14a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2.5A2.5 2.5 0 0 0 11.5 0h-7zM3 2.5A1.5 1.5 0 0 1 4.5 1h7A1.5 1.5 0 0 1 13 2.5v10.795a4.2 4.2 0 0 0-.776-.492C11.392 12.387 10.063 12 8 12s-3.392.387-4.224.803a4.2 4.2 0 0 0-.776.492V2.5z"/>
              </svg>
            </div>
            <span>Persoonlijke gegevens</span>
          </router-link>
        </li>
      </ul>
    </nav>
    <div id="sidebar-bottom">
      <div class="profile">
          <img class="profile-picture" src="https://randomuser.me/api/portraits/women/59.jpg">
          <span>Marianne Noten</span>
      </div>
      <ul class="nav justify-content-around">
        <li>
          <router-link to="/" class="d-flex btn btn-mnnu-menu">
              Uitloggen <i class="bi bi-box-arrow-right ml-2"></i>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppSidebar'
}
</script>

<template>
  <div id="auth-base-page">
    <div class="d-flex flex-column flex-fill">
      <div id="logo">
        <img :src="require('@/assets/img/mnnu_logo_lettermark_tagline.svg')" alt="MNNU">
      </div>

      <slot />

      <div class="spacer"></div>
    </div>

    <modal id="pageHelpModal" title="Inloggen / Registreren">
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ullamcorper aliquet nulla a facilisis. Vivamus aliquam ex ut justo suscipit, eu vestibulum justo dictum. Nulla varius, tortor nec tempor pellentesque, erat velit tincidunt libero, eu fringilla erat massa volutpat turpis. Aliquam et nibh massa. Vivamus nulla ipsum, ornare vitae felis ut, fringilla venenatis risus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. In sed ornare nisl. Mauris nec nunc quis purus varius aliquet. Integer a pellentesque ligula. Etiam et imperdiet urna.</p>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/ui/Modal'

export default {
  name: 'BaseAuth',
  components: { Modal }
}
</script>

<template>
  <div id="error-page" class="d-flex flex-column">
    <div id="content-container">
      <div id="content" class="text-center">
        <h2>
          404, <small>pagina niet gevonden</small>
        </h2>
        <p>Het lijkt erop dat u een deel van de applicatie probeert te bereiken die (nog) niet bestaat.</p>
        <router-link to="/" class="btn btn-primary btn-md">Terug naar START</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>

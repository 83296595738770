import Vue from 'vue'
import VueRouter from 'vue-router'
import BaseAuth from '@/layouts/BaseAuth'
import Base from '@/layouts/Base'
import NotFound from '@/views/error/NotFound'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'preAuth',
    // route level code-splitting
    // this generates a separate chunk (auth.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/PreAuth'),
    meta: { layout: BaseAuth }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/Login'),
    meta: { layout: BaseAuth }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/Register'),
    meta: { layout: BaseAuth }
  },
  {
    path: '/loading',
    name: 'loading',
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/Loading'),
    meta: { layout: BaseAuth }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "auth" */ '../views/terms/Privacy'),
    meta: { layout: BaseAuth }
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import(/* webpackChunkName: "auth" */ '../views/terms/Terms'),
    meta: { layout: BaseAuth }
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import(/* webpackChunkName: "contacts" */ '../views/contacts/Contacts'),
    meta: { layout: Base }
  },
  {
    path: '/contacts/:contactId',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/contacts/Contact'),
    props: true,
    meta: { layout: Base }
  },
  {
    path: '/user',
    name: 'user',
    component: () => import(/* webpackChunkName: "user" */ '../views/User'),
    meta: { layout: Base }
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
    meta: { layout: BaseAuth }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    contacts: [
      {
        contact_id: 1,
        contact_label: 'Fysiotherapiepraktijk Lexmond',
        logo_uri: '',
        requests: [
          {
            request_id: 1,
            status: 0,
            request_label: 'Algemeen fysiotherapeut Mevr. Kortman',
            description: 'Voor uw behandeling heb ik een aantal gegevens nodig.\nAlvast bedankt!',
            attributes: ['Naam', 'Telefoon'],
            created: '10 dagen geleden om 14:12 uur'
          },
          {
            request_id: 2,
            status: 1,
            request_label: 'App ontwikkelaar',
            description: 'Dit is een test voor de app.\nWeiger alstublieft dit verzoek.',
            attributes: ['Naam', 'Telefoon', 'Pasfoto'],
            created: '7 dagen geleden om 15:00 uur'
          },
          {
            request_id: 3,
            status: 3,
            request_label: 'App ontwikkelaar',
            description: 'Dit is nogmaals een test verzoek.\nDe omschrijving is dan ook niet relevant en enkel bedoeld voor de demo.',
            attributes: ['Voornaam', 'Achternaam', 'Telefoon', 'Adres', 'Postcode', 'Woonplaats'],
            created: '1 dag geleden om 23:46 uur'
          },
          {
            request_id: 4,
            status: 3,
            request_label: 'App ontwikkelaar',
            description: 'Dit is nogmaals een test verzoek.\nDe omschrijving is dan ook niet relevant en enkel bedoeld voor de demo.',
            attributes: ['Voornaam', 'Woonplaats'],
            created: 'Gisteren om 11:53 uur'
          }
        ]
      },
      {
        contact_id: 2,
        contact_label: 'Dokter Google',
        logo_uri: '',
        requests: []
      }
    ],
    current_user: {
      email: '',
      phone: '06-48957535',
      address: 'Kervelstraat 141',
      zip: '2034 NX',
      city: 'Haarlem'
    }
  },
  mutations: {
    setRequestStatus (state, { contactId, requestId, status }) {
      state.contacts
        .find(contact => contact.contact_id === contactId)
        .requests.find(request => request.request_id === requestId)
        .status = status
    },
    removeContact (state, contact) {
      state.contacts = state.contacts
        .filter(c => c.contact_id !== contact.contact_id)
    },
    addContact (state, token) {
      const lastContactId = Math.max.apply(Math, state.contacts.map(contact => contact.contact_id))
      const newContact = {
        contact_id: lastContactId + 1,
        contact_label: 'MNNU',
        logo_uri: '',
        requests: []
      }

      state.contacts.push(newContact)
    },
    setCurrentUserEmail (state, value) {
      state.current_user.email = value
    },
    setCurrentUserPhone (state, value) {
      state.current_user.phone = value
    },
    setCurrentUserAddress (state, value) {
      state.current_user.address = value
    },
    setCurrentUserZip (state, value) {
      state.current_user.zip = value
    },
    setCurrentUserCity (state, value) {
      state.current_user.city = value
    }
  },
  actions: {
    changeRequestStatus ({ commit }, payload) {
      commit('setRequestStatus', payload)
    },
    removeContact ({ commit }, payload) {
      commit('removeContact', payload)
    },
    addContact ({ commit }, payload) {
      commit('addContact', payload)
    }
  },
  modules: {
  },
  getters: {
    totalUnreadRequestsByContact: (state) => (contact) => {
      return state.contacts
        .find(x => x.contact_id === contact.contact_id)
        .requests.filter(request => request.status === 3).length
    },
    unreadRequestsByContact: (state) => (contact) => {
      return state.contacts
        .find(x => x.contact_id === contact.contact_id)
        .requests.filter(request => request.status === 3)
    },
    contactById: (state) => (id) => {
      return state.contacts.find(contact => contact.contact_id === id)
    }
  }
})

<template>
  <div class="modal fade" :id="id" tabindex="-1" :aria-labelledby="modalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div v-if="header" class="modal-header">
          <slot name="header">
            <h5 class="modal-title" :id="modalLabel">
              <slot name="title">{{title}}</slot>
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </slot>
        </div>

        <div class="modal-body">
          <slot />
        </div>

        <div v-if="footer" class="modal-footer">
          <slot name="footer">
            <button type="button" class="btn btn-primary btn-block" data-dismiss="modal">Sluiten</button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    id: {
      type: String,
      required: true
    },
    header: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: 'Modal title'
    },
    footer: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    modalLabel () {
      return `${this.id}Label`
    }
  }
}
</script>
